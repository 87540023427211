<template>
  <div class="home">
    <div class="header">
      <div class="header-left">
        <img class="logo-img" :src="logo" alt="" />
        <!-- <div class="phone phone-h">7*24h</div> -->
        <!-- <img class="Vector-img" :src="Vector" alt="" /> -->
        <div class="phone">
          <div class="phone-item">24小时服务热线</div>
          <div class="phone-item">028-60192371</div>
        </div>
        <!-- <div class="phone phone-l">028-60192371</div>13689021734 -->
      </div>
      <div class="header-right">四川巨源基因科技有限公司</div>
    </div>
    <div class="banner">
      <img class="banner-img" :src="banner" alt="" />
    </div>
    <div class="brief">
      <div class="brief-left">
        <div class="brief-left-title">技术简介</div>
        <div class="brief-left-eng">TECHNICAL&nbsp;INTRODUCTION</div>
        <div class="brief-left-main">
          <span>10X&nbsp;Genomics&nbsp; 单细胞</span>
          转录组测序平台利用<span>微流控、Barcode标记</span>和
          <span> 油滴包裹</span>
          等技术实现高通量的细胞捕获，可在10min内自动完成上千万个细胞的捕获，实现细胞的快速高效标记、测序和分析，获得单细胞水平的基因表达谱和差异情况。
        </div>
      </div>
      <div class="brief-right">
        <img class="brief-right-img" :src="technology" alt="" />
      </div>
    </div>
    <div class="principle">
      <div class="principle-left">
        <img class="principle-left-img" :src="Mask_group" alt="" />
      </div>
      <div class="principle-right">
        <div class="principle-right-title">技术原理</div>
        <div class="principle-right-eng">TECHNICAL&nbsp;PRIONCIPLE</div>
        <div class="principle-right-top">
          10x Genomics的Chromium系统利用8通道的微流体 “双十字”
          交叉系统，将含barcode的凝胶珠（Gel
          Beads）．细胞和酶的混合物、油三者混合，形成GEMs
          (油包水的微体系），GEMs形成后，细胞裂解，凝胶珠自动溶解释放大量barcode序列。
        </div>
        <div class="principle-right-middle">
          将每个液滴中含有10x
          barcode信息的产物混合，反转录成CDNA之后，酶切打断，加上测序接头，构建二代测序文库。待测序后，根据10xbarcode序列进行数据拆
          分，并利用10x Genomics提供的可特异性解析10x
          barcode的分析软件进行数据分析及可视化展示。
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="advantage-title">技术优势</div>
      <div class="advantage-eng">TECHNOLOGICAL ADVANTAGE</div>
    </div>
    <div class="technical">
      <div
        class="technical-item"
        v-for="(item, index) in TechList"
        :key="index"
      >
        <div class="technical-item-left">
          <img class="item-left-img" :src="Union" alt="" />
        </div>
        <div class="technical-item-right">
          <div class="item-right-title">{{ item.title }}</div>
          <div class="item-right-main">
            <span class="span-left">{{ item.content_left }}</span>
            <span class="span-blue">{{ item.content_blue }}</span>
            <span class="span-right">{{ item.content_right }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="advantage-title">公司优势</div>
      <div class="advantage-eng">CORPORATE ADVANTAGE</div>
    </div>
    <div class="company">
      <div
        class="company-item"
        v-for="(item, index) in CompanyList"
        :key="index"
      >
        <img class="company-item-img" :src="item.img" alt="" />
        <div class="company-item-main">
          <span class="span-bold">{{ item.span1 }}</span>
          <span>{{ item.span2 }}</span>
          <span class="span-bold">{{ item.span3 }}</span>
          <span>{{ item.span4 }}</span>
        </div>
      </div>
    </div>
    <div class="sample">
      <div class="sample-top">
        <div class="sample-top-title">样本处理</div>
        <div class="sample-top-eng">SAMPLE PROCESSING</div>
      </div>
      <div class="sample-main">
        <div class="sample-main-left">
          <div class="main-left-top">
            细胞悬液，血液，类器官，动植物新鲜组织等（提供上门取样服务，当日解离上机）
          </div>
          <img class="main-left-img" :src="Group4" alt="" />
        </div>
        <div class="sample-main-right">
          <div class="main-right-top">
            上机标准（组织需解离成单细胞悬液，植物细胞需制成原生质体）：
          </div>
          <div class="main-right-bottom">
            <div
              class="right-bottom-item"
              v-for="(item, index) in SampleList"
              :key="index"
            >
              <div class="bottom-item-left"></div>
              <div class="bottom-item-middle">{{ item.title }}</div>
              <div class="bottom-item-right">{{ item.main }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img class="footer-img" :src="Group5" alt="" />
      <div class="footer-title">四川巨源基因科技有限公司</div>
      <div class="footer-main">
        <img
          class="footer-main-img"
          :src="map_pin"
          alt=""
        />成都市高新科技孵化园1号楼A座5楼35号附1号
      </div>
      <!-- <div class="phone-item"></div> -->
      <div class="footer-main">
        <img class="footer-main-img" :src="phone_mini" alt="" />028-60192371
      </div>
      <div class="footer-main">
        <img class="footer-main-img" :src="phone_mini" alt="" />13689021734
      </div>
    </div>
    <div class="footerinfo">
      <a href="https://beian.miit.gov.cn" >蜀ICP备2023022192号-1</a> | 2023 版权所有 四川巨源基因科技有限公司
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      banner: require("@/assets/images/banner@2x.png"), //分类图标
      logo: require("@/assets/images/logo@2x.png"),
      Vector: require("@/assets/images/Vector@2x.png"),
      technology: require("@/assets/images/technology@2x.png"),
      Mask_group: require("@/assets/images/Mask-group@2x.png"),
      Union: require("@/assets/images/Union@2x.png"),
      Group4: require("@/assets/images/Group4@2x.png"),
      Group5: require("@/assets/images/Group5@2x.png"),
      map_pin: require("@/assets/images/map-pin@2x.png"),
      phone_mini: require("@/assets/images/phone-mini@2x.png"),
      TechList: [
        {
          title: "高通量",
          content_left: "可以同时检测",
          content_blue: " 8 ",
          content_right: "个样本，细胞通量高达上万个细胞",
        },
        {
          title: "周期短",
          content_left: "一天之内可完成细胞悬液制备、单细胞捕获、扩增以及建库",
          content_blue: "",
          content_right: "",
        },
        {
          title: "捕获效率高",
          content_left: "细胞捕获效率高达",
          content_blue: " 65%",
          content_right: "",
        },
        {
          title: "双细胞发生率低",
          content_left: "多细胞率低于0.9%/1000cells",
          content_blue: "",
          content_right: "",
        },
        {
          title: "技术成熟",
          content_left:
            "可检出单个细胞的配对的TCR/BCR双链V（D）J全长序列并进行针对ATAC分析，线粒体污染率低于2%",
          content_blue: "",
          content_right: "",
        },
        {
          title: "应用范围广",
          content_left:
            "细胞系、原代细胞、分离的新鲜动植物细胞均可以进行单细胞测序",
          content_blue: "",
          content_right: "",
        },
      ],
      CompanyList: [
        {
          img: require("@/assets/images/Group1@2x.png"),
          span1: "西南地区唯一一家 ",
          span2: "单细胞测序为主的本地基因公司",
          span3: "",
          span4: "",
        },
        {
          img: require("@/assets/images/Group2@2x.png"),
          span1: "",
          span2: "10x Genomics ",
          span3: "唯一指定独家Demo",
          span4: "实验室提供",
        },
        {
          img: require("@/assets/images/Group3@2x.png"),
          span1: "7*24小时在线服务，",
          span2: "上门取样服务，当日解离上机，",
          span3: "4小时出结果",
          span4: "",
        },
      ],
      SampleList: [
        {
          title: "样品总量",
          main: "制备成细胞悬浮液，细胞起始量需大于1x10⁵个",
        },
        {
          title: "样品浓度",
          main: "上样细胞浓度为700-1,200 cells/μL",
        },
        {
          title: "细胞活性",
          main: "细胞活率≥85%",
        },
        {
          title: "细胞大小",
          main: "细胞直径小于40μm",
        },
        {
          title: "细胞培养基及缓冲液不能含有Ca 2+和Mg 2+等影响酶活性的物质",
          main: "",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 1903px;
  box-sizing: border-box;
  margin: 0 auto;
  //溢出隐藏

  .header {
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    background: #ffffff;
    padding: 0 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
      .logo-img {
        width: 233px;
        height: 42px;
      }
      .Vector-img {
        width: 16px;
        height: 13px;
        // margin-left: 32px;
        margin-right: 4px;
        margin-left: 5px;
      }
      .phone {
        font-size: 16px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #28a8f1;
        line-height: 23px;
        width: 300px;
        box-sizing: border-box;
        padding-left: 20px;
        height: 46px;
        .phone-item {
          display: flex;
          height: 23px;
          align-items: center;
        }
      }
    }
    .header-right {
      font-size: 24px;
      font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
      font-weight: 400;
      color: #1d3658;
      line-height: 30px;
    }
  }
  .banner {
    width: 100%;
    height: 480px;
    .banner-img {
      width: 100%;
      height: 100%;
    }
  }
  .brief {
    width: 100%;
    height: 448px;
    box-sizing: border-box;
    background-image: url("@/assets/images/bg@2x.png");
    overflow: hidden;
    padding: 80px 360px 0px 360px;
    display: flex;
    justify-content: space-between;

    .brief-left {
      width: 460px;
      box-sizing: border-box;
      padding: 45px 0px 0px 0px;
      .brief-left-title {
        height: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #1d3658;
        line-height: 30px;
      }
      .brief-left-eng {
        height: 30px;
        font-size: 24px;
        font-family: Arial-Regular, Arial;
        font-weight: 400;

        color: rgba($color: #1d3658, $alpha: 0.2);
        line-height: 30px;
      }
      .brief-left-main {
        margin-top: 20px;
        font-size: 15px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: 400;
        color: #13233a;
        line-height: 25px;
        span {
          font-weight: bold;
        }
      }
    }
    .brief-right {
      width: 620px;
      height: 288px;
      .brief-right-img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .principle {
    width: 1400px;
    height: 547px;
    margin: 0 auto;
    background: #f1f8ff;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    padding: 80px 100px;
    display: flex;
    justify-content: space-between;
    .principle-left {
      width: 602px;
      height: 387px;
      .principle-left-img {
        width: 100%;
        height: 100%;
      }
    }
    .principle-right {
      width: 460px;
      .principle-right-title {
        height: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #1d3658;
        line-height: 30px;
      }
      .principle-right-eng {
        height: 30px;
        font-size: 24px;
        font-family: Arial-Regular, Arial;
        font-weight: 400;
        color: rgba($color: #1d3658, $alpha: 0.2);
        line-height: 30px;
      }
      .principle-right-top {
        margin-top: 20px;
        font-size: 15px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #13233a;
        line-height: 25px;
      }
      .principle-right-middle {
        font-size: 15px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #13233a;
        line-height: 25px;
      }
    }
  }
  .advantage {
    width: 100%;
    height: 193px;
    box-sizing: border-box;
    padding-top: 80px;
    .advantage-title {
      width: 100%;
      height: 30px;
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
      font-weight: bold;
      color: #1d3658;
      line-height: 30px;
    }
    .advantage-eng {
      width: 100%;
      height: 30px;
      text-align: center;
      font-size: 24px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: rgba($color: #1d3658, $alpha: 0.2);
      line-height: 30px;
    }
  }
  .technical {
    width: 1374px;
    height: 328px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    //可以换行
    flex-wrap: wrap;
    .technical-item {
      width: 450px;
      height: 140px;
      background: linear-gradient(180deg, #f6f8fb 0%, #ffffff 100%);
      box-shadow: 8px 8px 20px 0px #f3f5f8;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      box-sizing: border-box;
      padding-top: 27px;
      .technical-item-left {
        width: 50px;
        margin-right: 15px;
        .item-left-img {
          width: 25px;
          height: 18.5px;
          margin-left: 25px;
        }
      }
      .technical-item-right {
        .item-right-title {
          margin-top: 9px;
          height: 25px;
          font-size: 20px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #28a8f1;
          line-height: 25px;
        }
        .item-right-main {
          margin-top: 12px;
          height: 28px;
          font-size: 15px;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          color: #13233a;
          .span-blue {
            color: #28a8f1;
            font-size: 20px;
          }
        }
      }
    }
  }
  .company {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .company-item {
      width: 450px;
      height: 266.13px;
      //相对定位
      position: relative;
      .company-item-img {
        width: 420px;
        height: 236.13px;
      }
      .company-item-main {
        width: 396px;
        height: 144px;
        //决定定位
        position: absolute;
        top: 122px;
        left: 54px;
        background: #28a8f1;
        border-radius: 4px 4px 4px 4px;
        box-sizing: border-box;
        padding: 28px 22px 0px 28px;
        font-size: 20px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        color: #ffffff;
        line-height: 30px;
        .span-bold {
          font-weight: bold;
        }
      }
    }
  }
  .sample {
    width: 1903px;
    height: 637px;
    box-sizing: border-box;
    background-image: url("@/assets/images/bg-2@2x.png");
    overflow: hidden;
    .sample-top {
      width: 100%;
      height: 143px;
      box-sizing: border-box;
      padding-top: 50px;
      .sample-top-title {
        width: 100%;
        text-align: center;
        height: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #1d3658;
        line-height: 30px;
      }
      .sample-top-eng {
        width: 100%;
        text-align: center;
        height: 30px;
        font-size: 24px;
        font-family: Arial-Regular, Arial;
        font-weight: 400;
        color: rgba($color: #1d3658, $alpha: 0.2);
        line-height: 30px;
      }
    }
    .sample-main {
      width: 100%;
      padding: 0 260px;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      .sample-main-left {
        width: 674px;
        height: 450px;
        background: #f1f8ff;
        border-radius: 4px 4px 4px 4px;
        box-sizing: border-box;
        padding: 64px 42px 0px 42px;
        .main-left-top {
          font-size: 18px;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          color: #13233a;
          line-height: 23px;
        }
        .main-left-img {
          margin-top: 107px;
          width: 590px;
          height: 143px;
        }
      }
      .sample-main-right {
        width: 688px;
        height: 450px;
        .main-right-top {
          width: 688px;
          height: 91px;
          background: #f1f8ff;
          display: flex;
          align-items: center;
          font-size: 20px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #13233a;
          line-height: 23px;
          box-sizing: border-box;
          padding-left: 28px;
        }

        .main-right-bottom {
          width: 688px;
          height: 347px;
          margin-top: 12px;
          background: linear-gradient(180deg, #f1f8ff 0%, #ffffff 100%);
          border-radius: 4px 4px 4px 4px;
          box-sizing: border-box;
          padding: 37px 0px 0px 28px;
          .right-bottom-item {
            display: flex;
            align-items: center;
            height: 23px;
            margin-bottom: 31px;
            .bottom-item-left {
              width: 6px;
              height: 6px;
              background: #13233a;
              border-radius: 50%;
              margin-right: 8px;
            }
            .bottom-item-middle {
              height: 23px;
              margin-right: 12px;
              font-size: 18px;
              font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
              font-weight: bold;
              color: #1d3658;
              line-height: 23px;
            }
            .bottom-item-right {
              height: 23px;
              font-size: 15px;
              font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
              font-weight: 400;
              color: #1d3658;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 400px;
    background: url("@/assets/images/fotter-img@2x.png") no-repeat center center /
      100% 100%;
    box-sizing: border-box;
    padding: 49px 0px 0px 260px;
    .footer-img {
      width: 178px;
      height: 180px;
    }
    .footer-title {
      height: 30px;
      margin-top: 28px;
      margin-bottom: 12px;
      font-size: 24px;
      font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
      font-weight: 400;
      color: #95adcd;
      line-height: 30px;
    }
    .footer-main {
      height: 18px;
      font-size: 14px;
      font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
      font-weight: 400;
      color: #7a8fab;
      line-height: 16px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;

      .footer-main-img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
  }
  .footerinfo{
    width: 100%;
    height: 50px;
    background: #2c2c2c;
    line-height: 50px;
    color: #898989;
    text-align: center;
    a{
    color: #fff;
    text-decoration: underline;
    }
  }
}
</style>
